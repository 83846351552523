import React from 'react'
import footerLogo from "../assets/images/hero-right.png"
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
           <footer>
      <div class="container">
        <div class="row">
          <div class="col-4">
            <img class="fLogo" src={footerLogo} />
            {/* <!-- <p class="mt-2">
            AlienForm (A4M) is an innovative, decentralized 
digital assets project that stands at the forefront
of transparency, community-led governance, and accountability. 
          </p> --> */}
          </div>
          <div class="col-lg-2 col-sm-12">
            <h6 class="widgets_title">Platform Links</h6>
            <div class="menu_list">
              
              <a href="#about">About </a>
              <a href="#supply"> Supply </a>

              <a href="#starmap">Starmap </a>
              <a href="#community">Community </a>

              <a href="#artical">Articles </a>

              <a href="#whitepaper"> AlienPaper </a>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <h6 class="widgets_title">Products</h6>
            <div class="menu_list">
              {/* <!-- <a href="https://university.alien4m.com/" target="_blank">AlienForm University
             </a> --> */}
              <a href="https://bridge.alien4m.com/" target="_blank">
                AlienForm Bridge
              </a>
              {/* <!-- <a href="https://portfolio.alien4m.com/" target="_blank">AlienForm Portfolio</a> --> */}
              {/* <!-- <a href="https://store.alien4m.com/">AlienForm Merch</a> --> */}
            </div>
          </div>
          <div class="col-lg-2 col-sm-12">
            <h6 class="widgets_title">Social Links</h6>
            <div class="menu_list">
              <a href="https://twitter.com/alien_4m" target="_blank">Twitter</a>
              <a href="https://www.youtube.com/@Alien_4M" target="_blank"
                >Youtube</a
              >
              <a href="https://t.me/Alien_4M" target="_blank">Telegram</a>
              <a href="https://discord.com/invite/pycvZERrRd" target="_blank"
                >Discord</a
              >
              <a href="https://www.reddit.com/r/Alien_4M/" target="_blank"
                >Reddit</a
              >
              <a
                href="https://www.facebook.com/alien4m.community"
                target="_blank"
                >Facebook</a
              >
              <a href="https://www.instagram.com/alien_4m/" target="_blank"
                >Instagram</a
              >
            </div>
          </div>
          <hr class="mt-4" />
          <div class="row secondFooter">
            <div class="col-lg-12">
              AlienForm Corp. Chamerstrasse 172, 6300 Zug,
              Switzerland
            </div>
            <div class="col-lg-6 col-sm-12 copyText mt-2">
             
            <Link to="/terms-of-service" target="_blank">Terms of Service |</Link>
            <Link to="privacy-policy" target="_blank">Privacy Policy |</Link>
            <Link to="disclaimer" target="_blank" style={{color:"red"}}>Disclaimer</Link>
         
       

            </div>
            <div class="col-lg-6 col-sm-12 copyText mt-2">
              <p>copyright: © 2023 AlienForm, A4M. All rights are reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Footer