import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import alienformcoin from "../assets/images/alienform-coin.png";
import alienformtonkenomics from "../assets/images/alienform-tonkenomics.png";
import maskLogo from "../assets/images/maskLogo.png";
import uniSwp from "../assets/images/uniSwp.png";
import pan from "../assets/images/pan.png";
import bi from "../assets/images/bi.png";
import mexc from "../assets/images/mexc.png";
import Cg from "../assets/images/Cg.png";
import Aud from "../assets/images/Aud.png";
import Cm from "../assets/images/Cm.png";
import starmapTitleBorder from "../assets/images/starmapTitleBorder.png";
import bellowBorder from "../assets/images/bellowBorder.png";
import top2025 from "../assets/images/top2025.png";
import tele from "../assets/images/tele.png";
import disc from "../assets/images/disc.png";
import red from "../assets/images/red.png";
import y from "../assets/images/y.png";
import tic from "../assets/images/tic.png";
import { Link } from "react-router-dom";
import x from "../assets/images/x.png";
import f from "../assets/images/f.png";
import instagram from "../assets/images/instagram.png";
import stone from "../assets/images/stone.jpg";
import Drew from "../assets/images/Drew.jpg";
import mt from "../assets/images/mt.jpg";
import Mary from "../assets/images/Mary.jpg";
import wolf from "../assets/images/wolf.jpg";
import phonics from "../assets/images/phonics.jpg";
import kd from "../assets/images/kd.jpg";
import Doug from "../assets/images/Doug.jpg";
import mediaAboveTitle from "../assets/images/mediaAboveTitle.png";
import ca from "../assets/images/ca.png";
import blo from "../assets/images/blo.png";
import coin from "../assets/images/coin.png";
import coinPaper from "../assets/images/coinPaper.png";
import cry from "../assets/images/cry.png";
import yo from "../assets/images/yo.png";
import bchain from "../assets/images/bchain.png";
import whitepaper from "../assets/images/Whitepaper.pdf";
import AlienFormauditreport from "../assets/images/AlienForm_audit.pdf";

function Home() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  return (
    <div>
      <video
        autoplay
        playsinline
        loop
        id="myVideo"
        width="100%"
        height="100%"
        src="./images/vecteezy_space.mp4"
        type="video/mp4"
      ></video>

      <section className="heroSec pt-150 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-md-12">
              <h2 className="heroTitle">
                THIS IS THE CRYPTO UPRISING YOU’VE BEEN LOOKING FOR.
              </h2>
              <div className="herrowButtonSection">
                <a href="#about" className="btn">
                  <div className="btn-content">Join The Invasion</div>
                </a>
                <div className="btn">
                  <a href="#whitepaper" className="btn-content">
                    AlienPaper (whitepaper)
                  </a>
                </div>
                {/* <!-- <button className="btn_ animated-button">
            <svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
              <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
              <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
            </svg>
            <span>Join The Rebellion</span>
          
            </button> --> */}
                {/* <!-- <button className="btn_">Alien Paper (whitepaper)</button> --> */}
              </div>
              <img className="coinImg floating" src={alienformcoin} />
            </div>
          </div>
        </div>
      </section>
      <section
        className="AboutMission text-align-center pt-100 pb-100"
        id="about"
      >
        <div className="container">
          <div className="row">
            <div className="w-80">
              <div className="col-lg-12 col-md-12 col-sm-12 col-md-12">
                <h2 className="heroTitle black">ABOUT OUR MISSION</h2>

                <h6 className="Tblack">
                  Welcome aboard the AlienForm Mothership, powered by community
                  and built on trust. Imagine an ecosystem where your growth and
                  security are paramount, and knowledge leads to empowerment.
                  AlienForm (A4M) is an innovative, decentralized digital assets
                  project, emphasizing transparency, community governance, and
                  accountability. We build trust with actions, including
                  multi-signature wallets and monthly financial reports,
                  showcasing our commitment to secure and open operations.
                </h6>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-md-12 mt-5">
                <h2 className="heroTitle black">UTILITY</h2>

                <h6 className="Tblack">
                  With AlienForm, delve into a suite of utilities designed for
                  the discerning crypto enthusiast: Level up at AlienForm
                  University, elevate your trading with Trade Probe, and
                  seamlessly manage your assets with Portfolio Manager. Your
                  journey to financial empowerment begins here.
                </h6>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3">
                <div className="bgBlue" onClick={handleShow8}>
                  <h3>AlienForm University (A4MU):</h3>
                  <p>Cultivate your trading acumen in our crypto academy.</p>
                  <a className=""> Learn more > </a>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="bgBlue" onClick={handleShow10}>
                  <h3>AlienForm TradeProbe:</h3>
                  <p>
                    Navigate the market with precision using our advanced
                    analytical tool.
                  </p>
                  <a className=""> Learn more > </a>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="bgBlue" onClick={handleShow9}>
                  <h3>
                    AlienForm Portfolio
                    <br />
                    Manager:
                  </h3>
                  <p>
                    Manage your assets with our sophisticated portfolio tracker.
                  </p>
                  <a className="">Learn more > </a>
                </div>
              </div>
              <div className="col-lg-3">
                <a
                  href="https://bridge.alien4m.com/"
                  target="_blank"
                  className="bgBlue"
                >
                  <h3>
                    AlienForm
                    <br />
                    Bridge:
                  </h3>
                  <p>
                    Enables seamless and rapid transfers between different
                    blockchains. 
                  </p>
                  Enter the Bridge >
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="supplySection " id="supply">
        <div className="container mb-40px">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <h2 className="heroTitle">THE A4M TOKEN</h2>
              <div className="tokenSec mt-5">
                <p className="border_button">
                  Total Supply
                  <span>100,000,000,000</span>
                </p>
                <p className="border_button">
                  Circulating Supply
                  <span>79,250,757,236</span>
                </p>
                <p className="border_button">
                  Burn
                  <span>20,749,242,764</span>
                </p>
              </div>
              <div className="mt-5">
                <p className="border_button border-none">
                  <small>Contract Address</small>
                  <span>
                    <input
                      className="inPut"
                      type="text"
                      value="0xf92D62Ed69242D655E685C96B98f32F1409c3262"
                      id="myInput"
                    />
                    <button onclick="myFunction()" className="btn_tr">
                      <i
                        className="fa-regular fa-clone pl-2"
                        onclick="myFunction()"
                      ></i>
                    </button>
                  </span>
                  {/* <!-- <span>0xf92D62Ed69242D655E685C96B98f32F1409c3262 <span onclick="myFunction()"><i className="fa-solid fa-copy"></i></span>
--> */}
                </p>
              </div>

              <h2 className="heroTitle mt-5">TOKENOMICS</h2>
              <img className="zoom" src={alienformtonkenomics} />

              <div className="pt-100 " id="whitepaper"></div>

              <h2 className="heroTitle mt-5 whitPaperSection">
                ALIENPAPER (WHITEPAPER)
              </h2>
              <div className="whitePapBtn mt-5">
                <a href="https://alien4m.com/Whitepaper.pdf" target="_blank">
                  READ OUR
                  <br />
                  ALIENPAPER
                </a>
                <a href="#listed">
                  BUY THE A4M
                  <br />
                  TOKEN
                </a>
              </div>
              <div className="pt-100 pb-50" id="listed">
                <img className="maskLogo" src={maskLogo} />

                <h2 className="heroTitle mt-5">ALIENFORM IS LISTED</h2>
                <div className="row mt-5 listed">
                  <div className="col-lg-3">
                    <img src={uniSwp} />
                    <p>
                      Uniswap is a decentralized crypto exchange that enables
                      users to trade Ethereum tokens in a secure and trustless
                      environment.
                      <a
                        style={{ display: "block" }}
                        target="_blank"
                        href="https://app.uniswap.org/swap?chain=eth&outputCurrency=0xf92D62Ed69242D655E685C96B98f32F1409c3262"
                      >
                        Go to Uniswap >
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={pan} />
                    <p>
                      PancakeSwap is a decentralized cryptocurrency exchange
                      that allows users to trade digital assets securely on the
                      Binance Smart Chain.
                      <a
                        style={{ display: "block" }}
                        target="_blank"
                        href="https://pancakeswap.finance/swap?outputCurrency=0xf92D62Ed69242D655E685C96B98f32F1409c3262"
                      >
                        Go to PancakeSwap >
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={bi} />
                    <p>
                      BitMart is a user-friendly centralized crypto exchange
                      that allows users to trade hundreds of digital assets.
                      <a
                        sstyle={{ display: "block" }}
                        target="_blank"
                        href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=A4M_USDT"
                      >
                        Go to BitMart >
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={mexc} />
                    <p>
                      MEXC is one of the world's leading digital asset
                      exchanges. Trusted by over 7 million users worldwide, more
                      than 1,100 listed cryptocurrencies, and intuitive usage.
                      <a
                        sstyle={{ display: "block" }}
                        target="_blank"
                        href="https://www.mexc.com/exchange/A4M_USDT?_from=search"
                      >
                        Go to MEXC >
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row mt-5 ListedIcon">
                  <div className="col-lg-4">
                    <a
                      href="https://www.coingecko.com/en/coins/alienform"
                      target="_blank"
                    >
                      <img className="zoom" src={Cg} />
                    </a>
                  </div>
                  <div className="col-lg-4">
                    <a href={AlienFormauditreport} target="_blank" rel="noreferrer">
                      <img className="zoom" src={Aud} />
                    </a>
                  </div>
                  <div className="col-lg-4">
                    <a
                      href="https://coinmarketcap.com/currencies/alienform/"
                      target="_blank"
                    >
                      <img className="zoom" src={Cm} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="supplySection roadMa" id="starmap">
        <div className="container mb-40px">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <h2 className="heroTitle">STARMAP BLUEPRINTS</h2>
              <p>
                Chart the Course: Our Blueprint to the Financial Cosmos has
                begun, embark on this intergalactic journey with us.
              </p>
              <img src={starmapTitleBorder} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <h2 className="heroTitle">2023: BLASTOFF</h2>
            </div>
            <div className="col-lg-4">
              <h3>Q2</h3>
              <p>Formation of Board of Directors</p>
            </div>
            <div className="col-lg-4">
              <h3>Q3</h3>
              <p>Name reveal (AlienForm)</p>
            </div>
            <div className="col-lg-4">
              <h3>Q4</h3>
              <p>
                Audit of AlienForm’s SmartContracts by Salus
                <br />
                A4M Token Airdrop (BSC)
                <br />
                AlienForm Bridge deployed
                <br />
                6 Articles Published
                <br />
                AlienForm Launched on ETH and BSC
                <br />
                A4M Airdrop + Listing on BitMart
              </p>
            </div>
            <div className="col-12 mt-5 text-align-center">
              <img src={bellowBorder} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px mt-5">
              <h2 className="heroTitle">2024: GALACTIC MILESTONES</h2>
            </div>
            <div className="col-lg-3">
              <h3>Q1</h3>
              <p>
                First Financial Report Shared 
                <br />
                First Influencer Relationship (GirlGoneCrypto) 
                <br />
                New Article Published 
                <br />
                Founded AlienForm Corp. 
                <br />
                AlienForm Tier1 CEX Listing
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Q2</h3>
              <p>
                AlienForm University
                <br />
                AlienForm Trade Probe
                <br />
                AlienForm Portfolio Manager (Phase 1)
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Q3</h3>
              <p>AlienForm Portfolio Manager (Phase 2)</p>
            </div>
            <div className="col-lg-3">
              <h3>Q4</h3>
              <p>AI Automation (TBA) (Phase1)</p>
            </div>
            <div className="col-12 mt-5 text-align-center">
              <img src={top2025} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px mt-5">
              <h2 className="heroTitle">2025: A NEW FRONTIER</h2>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <h3>Q1</h3>
              <p>Trading Strategy Lab</p>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </section>

      <section className="supplySection community" id="community">
        <div className="container mb-40px">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 text-align-center pt-100 mb-40px">
              <h2 className="heroTitle">THE ALIENFORM COMMUNITY</h2>
            </div>
            <div className="socialSection">
              <div className="social">
                <button className="border_button">ENGAGE</button>
                <ul className="socialList">
                  <li>
                    <a href="https://t.me/Alien_4M" target="_blank">
                      <img src={tele} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/pycvZERrRd"
                      target="_blank"
                    >
                      <img src={disc} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.reddit.com/r/Alien_4M/"
                      target="_blank"
                    >
                      <img src={red} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="social">
                <button className="border_button">WATCH</button>
                <ul className="socialList">
                  <li>
                    <a href="https://www.youtube.com/@Alien_4M" target="_blank">
                      <img src={y} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@alien.4m" target="_blank">
                      <img src={tic} />
                    </a>
                  </li>
                  {/* <!-- <li>
              <a href="#"><img src="./images/red.png"/></a>
            </li> --> */}
                </ul>
              </div>
              <div className="social">
                <button className="border_button">CONNECT</button>
                <ul className="socialList">
                  <li>
                    <a href="https://twitter.com/alien_4m" target="_blank">
                      <img src={x} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/alien4m.community"
                      target="_blank"
                    >
                      <img src={f} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/alien_4m/"
                      target="_blank"
                    >
                      <img src={instagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px pt-100">
              <h2 className="heroTitle">
                A SHIP IS ONLY AS GOOD
                <br />
                AS ITS CREW
              </h2>
              <p>
                These are the people who ensure that we run a tight ship.
                <br /> This is our board of directors and advisory board.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px pt-25">
              <div className="teams">
                <div className="Team_card" onClick={handleShow}>
                  <h3>CEO</h3>
                  <div className="teamIcon">
                    <img src={stone} />
                  </div>

                  <p>Stone ></p>
                </div>
                <div className="Team_card" onClick={handleShow1}>
                  <h3>COO</h3>
                  <div className="teamIcon">
                    <img src={Drew} />
                  </div>
                  <p>Drew ></p>
                </div>
                <div className="Team_card" onClick={handleShow2}>
                  <h3>CRO</h3>
                  <div className="teamIcon">
                    <img src={mt} />
                  </div>
                  <p>Mr. T ></p>
                </div>
                <div className="Team_card" onClick={handleShow3}>
                  <h3>CFO</h3>
                  <div className="teamIcon">
                    <img src={Mary} />
                  </div>
                  <p>Mary Jane ></p>
                </div>
                <div className="Team_card" onClick={handleShow4}>
                  <h3>CBO</h3>
                  <div className="teamIcon">
                    <img src={wolf} />
                  </div>
                  <p>Wolf ></p>
                </div>
                <div className="Team_card" onClick={handleShow5}>
                  <h3>CCO</h3>
                  <div className="teamIcon">
                    <img src={phonics} />
                  </div>
                  <p>Phoenix ></p>
                </div>
                <div className="Team_card" onClick={handleShow6}>
                  <h3>CEDO</h3>
                  <div className="teamIcon">
                    <img src={kd} />
                  </div>
                  <p>KD ></p>
                </div>
                <div className="Team_card" onClick={handleShow7}>
                  <h3>ADVISORY</h3>
                  <div className="teamIcon">
                    <img src={Doug} />
                  </div>
                  <p>Doug ></p>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 text-align-center mb-40px pt-100"
              id="artical"
            >
              <img src={mediaAboveTitle} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <h2 className="heroTitle">ALIENFORM IN THE MEDIA</h2>
              <p>
                AlienForm is making cosmic waves, we're not just a blip on the
                radar; we're a supernova in the crypto space, illuminating paths
                for investors and enthusiasts alike. Stay tuned as we continue
                to defy the odds, crafting news-worthy milestones that echo
                across galaxies. Click the icons to read more.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <div className="teams aleienFoemMedia">
                <a
                  href="https://cryptoadventure.com/community/articles/strengthening-the-foundations-of-trust-meet-alienforms-dedicated-team/"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={ca} />
                  </div>
                  <p>
                    Strengthening the Foundations of Trust: Meet AlienForm’s
                    Dedicated Team
                  </p>
                </a>
                <a
                  href="https://blockster.com/from-the-ashes-of-shibnobi-how-alienform-revived-hope-in-the-defi-space"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={blo} />
                  </div>
                  <p>
                    From the Ashes of Shibnobi: How AlienForm Revived Hope in
                    the Defi Space
                  </p>
                </a>
                <a
                  href="https://coinmarketcap.com/community/articles/654b9e0d09e4ff3a71170846/"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={coin} />
                  </div>
                  <p>
                    Integrity In Action: The AlienForm Board's Bold Commitment
                  </p>
                </a>
                <a
                  href="https://coinpaper.com/2664/the-community-first-future-of-de-fi-alien-form-s-collective-vision-for-a-new-era"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={coinPaper} />
                  </div>
                  <p>The Community-First Future of DeFi:</p>
                </a>
                <a
                  href="https://cryptomode.com/press-releases/people-power-how-alienforms-grassroots-governance-is-redefining-defi/"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={cry} />
                  </div>
                  <p>
                    People Power: How A4m’s Grassroots Governance is Redefining
                    DeFi
                  </p>
                </a>
                <a
                  href="https://finance.yahoo.com/news/alienform-gears-official-launch-180000356.html?guce_referrer=aHR0cHM6Ly90LmNvLw&guce_referrer_sig=AQAAAIEK3pFPt8sGil3dHnmrQN7eu2Xt9X-sy5yrELDBZF5YoqiOHGwAJBqylRT7LKJrkyniAN4hk4DYqYqkwjL4a3ZlAb8-PMCHokAPPiYNFtWJGUhEoQNAlBQ49Ibn7-EX0PSrrk_oHzdobDLrFu3d1VLUFE31Fqj_Nuy8aH-0Qz7F&_guc_consent_skip=1706356449"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={yo} />
                  </div>
                  <p> AlienForm Gears Up For Official Launch</p>
                </a>
                <a
                  href="https://blockchainreporter.net/schools-in-welcome-to-alienform-university/#:~:text=A%20University%20For%20Everyone&text=As%20AlienForm's%20core%20principles%20have,potential%20of%20blockchain%20technology%20itself"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={bchain} />
                  </div>
                  <p> School’s In: Welcome To AlienForm University</p>
                </a>
                <a
                  href="https://cryptoadventure.com/community/articles/from-disillusionment-to-destiny-an-interview-with-alienforms-ceo/"
                  target="_blank"
                  className="Team_card"
                >
                  <div className="teamIcon">
                    <img src={ca} />
                  </div>
                  <p>
                     From Disillusionment To Destiny: An Interview With
                    AlienForm’s CEO
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal className="dark" show={show} onHide={handleClose}>
        <Modal.Body>
          <button type="button" class="close" onClick={handleClose} closeButton>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={stone} />
            </div>
            <div class="userDesc">
              <h3>
                Stone <span> Chief Executive Officer (CEO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/Miss_Stone_AT" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/Miss_Stone" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:stone@alien4m.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                Stone has been self-employed for 20 years, which has allowed her
                to gain a lot of experience in the business world. As our Chief
                Executive Officer Stone serves as the lead representative of the
                project. She frequently hosts and participates in Ask Me
                Anything (AMA) sessions to keep the community informed at all
                times. Stone always keeps the overview of the project.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show1} onHide={handleClose1}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose1}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={Drew} />
            </div>
            <div class="userDesc">
              <h3>
                Drew <span> Chief Operating Officer (COO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/MCA_BUCS" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/LRSCGOD" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:drew@alien4m.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                Drew, the Chief Operating Officer (COO) of AlienForm, boasting
                over 25 years of invaluable corporate expertise. Drew oversees
                the day-to-day operations, ensuring streamlined processes,
                efficient resource allocation, and optimal organizational
                performance. Drew's visionary leadership and extensive
                experience make him an indispensable asset to AlienForm's
                executive team.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show2} onHide={handleClose2}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose2}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={mt} />
            </div>
            <div class="userDesc">
              <h3>
                Mr. T <span> Chief Relationship Officer (CRO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/Mr_T_a4m" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/mr_t_a4m" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:mr.t@alien4m.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                Mr. T has many years of experience in tech recruitment, having
                built teams globally at a senior level, and is a business
                development gun. As our Chief Relationship Officer Mr. T is
                responsible for seeking out and maintaining strong relationships
                with the project’s community and partners. He actively searches
                for opportunities and invitations to gatherings that will help
                spread awareness and positivity.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show3} onHide={handleClose3}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose3}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={Mary} />
            </div>
            <div class="userDesc">
              <h3>
                Mary Jane
                <span> Chief Financial Officer (CFO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/undore" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/MaryJaneA4M" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:maryjane@alien4m.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                Mary Jane is a very successful businesswoman in real life. As
                our Chief Financials Officer Mary Jane manages the financial
                aspects of the project, including budgeting, financial
                reporting, and strategic financial planning.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show4} onHide={handleClose4}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose4}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={wolf} />
            </div>
            <div class="userDesc">
              <h3>
                Wolf
                <span> Chief Blockchain Officer (CBO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/Wolf_A4M" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/Wolf_A4M" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:wolf@alien4m.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                Wolf has a vast understanding and years of experience with
                digital ledger technology. As our Chief Blockchain Officer he
                closely monitors digital ledger activity to ensure AlienForm’s
                A4M digital assets contracts and utility contracts are not
                manipulated / exploited. Wolf provides technical assistance and
                knowledge of digital ledger technology to the community and
                other members of the board.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show5} onHide={handleClose5}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose5}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={phonics} />
            </div>
            <div class="userDesc">
              <h3>
                Phoenix <span> Chief Creative Officer (CCO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/Zer097B" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/imad_es" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
              </div>
              <p>
                As our Chief Creative Officer he developed AlienForm's Logo,
                oversees the creation and management of AlienForm's creative
                content and is ensuring a consistent and engaging brand
                presence.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show6} onHide={handleClose6}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose6}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={kd} />
            </div>
            <div class="userDesc">
              <h3>
                KD <span> Chief Education Officer (CEDO) </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/TheTradeDonkey" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/TRADEDONKEY" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a href="mailto:kevin@alien4mu.com" target="_blank">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </div>
              <p>
                KD is the Chief Education Officer (CEDO) for AlienForm, where he
                oversees AlienForm University (A4MU). With over 30 years of
                experience as a market trader and educator, KD brings a wealth
                of knowledge to his role. His passion for teaching is evident in
                his innovative approach, where he combines traditional
                educational methods with cutting-edge technology.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      <Modal className="dark" show={show7} onHide={handleClose7}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose7}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="modelImg">
              <img src={Doug} />
            </div>
            <div class="userDesc">
              <h3>
                Doug <span> Advisory </span>
              </h3>
              <div class="socialPop">
                <a href="https://twitter.com/KrazyManD" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://t.me/KrazyManD" target="_blank">
                  <i class="fa-brands fa-telegram"></i>
                </a>
              </div>
              <p>
                Doug is an expert in Decentralized Finance (crypto) and cyber
                security, Doug supported Drew in the project take over and
                provides valuable advice for the Board of Directors to make safe
                and sound decisions. Doug comes to us with over 15 years of
                experience in IT, Network, and Enterprise security. You can also
                find him routinely holding security training and crypto wallet
                (Especially cold wallet) education for the community.
              </p>
            </div>
          </div>
          <div class="dtail"></div>
        </Modal.Body>
      </Modal>

      {/* UTILITY */}

      {/* <!--   AlienForm University: Modal --> */}

      <Modal className="dark" show={show8} onHide={handleClose8}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose8}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="userDesc">
              <h3> AlienForm University: </h3>
              <p>
                In the ever-evolving landscape of cryptocurrency, knowledge is
                power. AlienForm University bridges the gap between novice and
                seasoned traders, providing a dynamic learning environment where
                users can cultivate the skills necessary to navigate the complex
                world of crypto trading. Our commitment extends beyond mere
                theoretical understanding, as AlienForm University employs a
                multifaceted approach encompassing lessons, quizzes, online
                content, and live trading videos.
                <span class="blink">Coming soon</span>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!--   AlienForm Portfolio Manager: Modal --> */}

      <Modal className="dark" show={show9} onHide={handleClose9}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose9}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="userDesc">
              <h3> AlienForm Portfolio Manager: </h3>
              <p>
                AlienForm Portfolio Manager is a cutting-edge platform tailored
                for cryptocurrency investors, offering a comprehensive suite of
                tools and features to optimize portfolio management in the
                dynamic crypto space.
                <span class="blink">Coming soon</span>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!--  AlienForm TradeProbe: Modal --> */}

      <Modal className="dark" show={show10} onHide={handleClose10}>
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={handleClose10}
            closeButton
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="teamDetail">
            <div class="userDesc">
              <h3> AlienForm TradeProbe: </h3>
              <p>
                AlienForm's Trade Probe is an advanced trading tool designed to
                cater to both conservative and aggressive traders. Leveraging a
                sophisticated algorithm, this tool pulls data from multiple
                sources, including market indicators, news sentiment, and
                historical trends, to generate insightful buy and sell signals.
                {/* <span class="blink">free Trial April 2 - April 16</span> */}
                <p></p>
                <p>
                  For access, users must hold at least 1M A4M tokens, and pay a
                  monthly subscription fee of $50 (or $500 for an annual
                  subscription).
                </p>
                <p> Connect a DeFi wallet with our website and get started! </p>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Home;
